<template>
  <main>
    <section class="horizon">
      <div
        v-if="route.name == 'Ferias'"
        class="container"
      >
        <div class="in-between">
          <h2 class="horizon__title">
            Ferias
          </h2>
          <div class="upper-date--container">
            <PeriodoReporte
              :show-p-anterior="false"
              :show-anno-anterior="false"
            />
          </div>
        </div>

        <div class="gr-12 card--group">
          <div
            v-if="!loading"
            class="row"
          >
            <template
              v-for="(feria, idx) in ferias"
              :key="idx"
            >
              <CardFormularioFerias
                :title="feria.title"
                :form-name-route="feria.big_slug"
                :hide-starred="true"
              />
            </template>
          </div>

          <div
            v-else
            class="row"
          >
            <p>Cargando ferias...</p>
          </div>
        </div>
      </div>

      <div class="container">
        <router-view />
      </div>
    </section>
  </main>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import moment from 'moment'
import PeriodoReporte from '@/components/PeriodoReporte.vue'
import CardFormularioFerias from '@/components/CardFormularioFerias.vue'

export default {
  components: {
    PeriodoReporte,
    CardFormularioFerias,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const ferias = ref([])
    const skip = ref(0)
    const total = ref(0)
    const loading = ref(true)
    const startDate = ref(moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD'))
    const endDate = ref(moment().format('YYYY-MM-DD'))
    const mongoURL = computed(() => store.getters.getMongoFeriasURL)
    const database = computed(() => store.getters.getDBDashboard)
    const periodoActualDesde = ref(store.getters.getPeriodoReporte[0]['desde'])
    const periodoActualHasta = ref(store.getters.getPeriodoReporte[0]['hasta'])
    const listOfFormsRoutes = computed(() => {
      let tempFormsRoutes = router.options.routes.filter((current) => current.name == 'Ferias')[0].children
      return tempFormsRoutes.map((current) => current.name)
    })

    const urlFetch = computed(
      () =>
        `${mongoURL.value}${database.value}/read/ferias/?start=${startDate.value} 00:00:00&end=${endDate.value} 23:59:59&skip=0&limit=100`
    )

    const fetchFerias = () => {
      axios
        .get(urlFetch.value)
        .then((response) => {
          ferias.value = [...ferias.value, ...response.data.collection]
          skip.value = ferias.value.length
          total.value = response.data.count
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchFerias()
    })

    return {
      route,
      loading,
      ferias,
      periodoActualHasta,
      periodoActualDesde,
      listOfFormsRoutes,
    }
  },
}
</script>

<style lang="scss" scoped>
.card__body {
  padding: 1rem;
  background: #f3f3f3;
  border: 1px solid #cccccc;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.card__title {
  font-family: Avenir;
  font-size: 1rem;
  font-weight: 800;
  line-height: 19px;
  margin-bottom: 0.5rem;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 44px;
  color: #333333;
  margin: 0;
}

a {
  font-size: 0.85rem;
  text-decoration: underline;
}

.in-between {
  margin-bottom: 2rem;
}
</style>
